.accueil_diaporama_template_2 {
    width: 100% !important;
    max-width: 100% !important;
    background: none;
    padding: 0 !important;
    border-radius: 0;
    margin-bottom: -20px;
    overflow: hidden;
    .slide {
        height: 600px;
        overflow:hidden;
        > div {
            height: 600px;
            width: 100%;
            position: relative;
            overflow: hidden;
        }
        img {
            max-width: 100%;
            width: auto;
            margin: auto;
            display: block;
        }
    }

    .center-img-slider {
        margin-top: 0;
        height: 600px;
        width:100%;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:cover;
        a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    .slide-text {

        a {
            z-index: 10;
        }
    }

    .content-slide-text{
        > p, > a.addcommentaires {
            padding: 20px;
            background: rgba(255, 255, 255, .7);
            color: #000;
        }
        > p {
            position: absolute;
            bottom: 30px;
            left: 20px;
            width: auto;
            z-index: 20;
        }
        > a .fa {
            margin-right: 3px;
        }
        > a.addcommentaires {
            top: 40px;
            right: 40px;
            position: absolute;
            text-decoration: none;
        }
        > a.slider-see-more {
            bottom: 120px;
            right: 60px;
            position: absolute;
            text-decoration: none;
        }
    }
    .bx-viewport {
        max-height: 600px;
    }
}

@media #{$medium-and-down}{
    .accueil_diaporama_template_2 .slide{
        &, > div, > div .center-img-slider{
            height: 400px;
        }
        .slide-text {
            max-height:400px;
        }
    }
}

@media #{$small-and-down}{
    .accueil_diaporama_template_2 .slide{
        &, > div, > div .center-img-slider{
            height: 300px;
        }
        .slide-text {
            max-height:300px;
        }
    }
}