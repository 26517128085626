.accueil_diaporama_template_1{
    margin:0 auto;
    .bx-wrapper{
        margin-bottom:35px;
    }
    .bx-pager{
        text-align: center;
        width: 100%;
    }
    .bx-viewport{
        //max-width:950px;
        margin:0 auto;
        height:100%;
        .slide > div:nth-child(n+2){
            position: relative;
            visibility: visible;
        }
    }
    .slide{
        max-height:600px;
        //max-width: 950px;
        height: 100%;
        overflow: hidden;
        margin: 0 auto;
        > div{
            min-height:100%;
            width:100%;
            max-width:100%;
            position:relative;
            &:nth-child(n+2){
                position: absolute;
                visibility: hidden;
            }
        }
    }
    .center-img-slider{
        margin-top: 0;
        margin-bottom: 0;
        width:100%;
        background-color:transparent;
        text-align:center;
        line-height: 0;
        img{
            min-width:0;
            width:auto;
            height:auto;
            max-width:100%;
            max-height:600px;
            display:inline-block;
            margin: 0;
        }
    }
    .slide-text {

    }
    .content-slide-text{
        > p, > a{
            position:absolute;
            padding: 20px;
            width:auto;
        }
        > p {
            bottom: 20px;
            left:20px;
            margin: 0 0 10px 0;
            background: rgba(255,255,255,0.7);
            color: #000;
        }
        > a {
            text-decoration: none;
            top: 20px;
            right:20px;
            &.addcommentaires{
                background: rgba(255,255,255,0.7);
                color: #000;
            }
            .fa {
                margin-right: 3px;
            }
        }
    }
}

@media #{$medium-and-down} {
    .accueil_diaporama_template_1{
        .slide{
            max-width:640px;
            max-height:500px;
        }
        .center-img-slider img{
            max-height:500px;
        }
    }
}
@media #{$small-and-down} {
    .accueil_diaporama_template_1{
        .slide{
            max-height:300px;
        }
        .center-img-slider img{
            max-height:300px;
        }
    }
}


