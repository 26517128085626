.accueil_diaporama_template_4{
    margin:10px auto 0;
    background:transparent;
    max-width:100%;
    overflow:hidden;
    .bx-wrapper{
        height:100%;
        margin:0;
        .bx-controls-auto{
            position:static;
            margin:10px 0;
        }
    }
    .bx-viewport{
        margin:0 auto;
    }
    .slide {
        padding: 0;
        margin: 0;
        width: 100000px;
        li {
            max-height: 600px;
            float: left;
            width: auto;
            position: relative;
            margin: 0 10px 0 0;
            list-style-type: none;
            &:hover {
                .slide-text {
                    opacity: 1;
                }
            }
            img {
                min-width: 100%;
                width: auto;
                min-height: 600px;
            }
        }

        &::after{
            content:'';
            clear: both;
            display: block;
        }
    }

    .center-img-slider{
        margin-top: 0;
        margin-bottom: 0;
        width:100%;
        background-color:transparent;
        text-align:center;
        font-size:0;
        line-height: 0;
        img{
            min-width:0;
            height:auto;
            max-height:600px;
            display:inline-block;
        }
    }
    .slide-text {

    }

    .content-slide-text{
        > p, > a.addcommentaires {
            position:absolute;
            padding: 20px;
            width:auto;
            color:#000;
            background:rgba(255,255,255,.7);
        }
        > p {
            bottom: 20px;
            left:20px;
            margin: 0 0 10px 0;
        }
        > a.addcommentaires {
            text-decoration: none;
            top: 20px;
            right:40px;
        }
        > a.slider-see-more {
            bottom: 120px;
            right: 60px;
            position: absolute;
            text-decoration: none;
        }
        > a .fa {
            margin-right: 3px;
        }
    }
}

@media #{$medium-and-down}{
    .accueil_diaporama_template_4 .slide li .center-img-slider img {
        max-height:400px;
        height: auto;
        max-width:100%;
        width:auto;
        min-height:400px;
    }
}

@media #{$small-and-down}{
    .accueil_diaporama_template_4{
        .slide li .center-img-slider img{
            max-height: 300px;
            min-height: 300px;
        }
        .slide-text{
            opacity:1;
        }
    }
}


