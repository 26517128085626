.accueil_diaporama_template_3{
    margin:0 auto;
    .bx-wrapper{
        height:100%;
        margin:0;
        .bx-controls-auto{
            position:static;
            margin:10px 0;
        }
    }
    .bx-viewport{
        max-width: 100%;
        margin:0 auto;
        height:100%;
        .slide > div:first-child{
            margin-bottom:0;
        }
    }
    .slide {
        max-height: 600px;
        max-width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0 auto;
        padding: 0;
        > li {
            min-height: 100%;
            width: 100%;
            position: relative;
            &:first-child {
                margin-bottom: 1000px;
            }
        }
    }

    .center-img-slider{
        margin-top: 0;
        width:100%;
        background-color:transparent;
        text-align:center;
        img{
            min-width:0;
            width:auto;
            height:auto;
            max-width:100%;
            max-height:600px;
            display:inline-block;
        }
    }
    .slide-text {

    }

    .content-slide-text{
        > p, > a{
            position:absolute;
            padding: 20px;
            width:auto;
        }
        > p {
            bottom: 20px;
            left:20px;
            margin: 0 0 10px 0;
            color:#000;
            background:rgba(255,255,255,.7);
        }
        > a {
            text-decoration: none;
            top: 20px;
            right:20px;
            &.addcommentaires{
                background: rgba(255,255,255,0.7);
                color: #000;
            }
            .fa {
                margin-right: 3px;
            }
        }
    }

    #bx-pager{
        line-height:0;
        text-align:center;
        > a{
            display:inline-block;
            width:14.666666666666666666%;
            height:auto;
            margin:0.5%;
            img {
                max-width: 100%;
            }
        }
    }
}

@media #{$medium-and-down} {
    .accueil_diaporama_template_1{
        .slide{
            max-width:640px;
            max-height:500px;
            .center-img-slider img{
                max-height:500px;
            }
        }
    }
}
@media #{$small-and-down} {
    .accueil_diaporama_template_1{
        .slide{
            max-height:300px;
            .center-img-slider img{
                max-height:300px;
            }
        }
    }
}

