@import '../../../../cmonsite/_main_mixin';
@import '../../../variables';
@import '../../mixin_templates.scss';

/*TEMPLATES*/

@import '_template_1';

@import '_template_2';

@import '_template_3';

@import '_template_4';

@import '_template_5';

@import '_template_6';

.accueil_diaporama_template{
    box-sizing:border-box;
    padding:0;
    clear:both;
    position: relative;
    z-index: 1;
    a.bx-prev, a.bx-next, .bx-pager-item a{
        &,&:focus{
            outline: none;
        }
    }
    a.bx-next{
        left:auto;
        right:0;
        background: url(/images/controls.png) no-repeat -43px -32px;
        text-indent: -9999px;
    }
    a.bx-prev{
        left:0;
        background: url(/images/controls.png) no-repeat 0 -32px;
        text-indent: -9999px;
    }
}

@media #{$small-and-down} {
    .accueil_diaporama_template p{
        padding:5px;
    }
    .accueil_diaporama_template .slide a{
        height:auto;
        padding:15px 15px;
        line-height:normal;
        font-size:15px;
        width:auto;
        text-align:center;
    }
}