.accueil_diaporama_template_5{
    margin:0 auto;
    overflow: hidden;
    max-width: 100%;
    .bx-wrapper{
        height:100%;
        margin-bottom:10px;
    }
    .bx-viewport{
        margin:0 auto;
        height:100%;
        .slide{
            > div{
                &:hover{
                    .slide-text{
                        opacity:1;
                    }
                }
                &:nth-child(n+4){
                    display: inline-block;
                }
                .slide-text{
                    display: inline-block;
                }
            }
        }
    }
    .slide {
        height: 100%;
        overflow: hidden;
        font-size: 0;
        text-align: center;
        > div {
            width: calc((100% - 20px) / 3);
            float: none;
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
            font-size: 1rem;
            position: relative;
            &:nth-child(3) {
                margin-right: 0;
            }
            &:nth-child(n+4) {
                display: none;
            }
        }
    }

    .center-img-slider{
        margin: 0;
        width:100%;
        height:300px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color:transparent;
        text-align:center;
        font-size: 0;
        line-height: 0;
        a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    .slide-text {
        opacity:0;
        transition:opacity .2s linear;

    }

    .content-slide-text{
        > p, > a{
            position:absolute;
            padding: 10px;
            width:auto;
        }
        > p {
            bottom: 20px;
            left:20px;
            margin: 0 0 10px 0;
            color:#000;
            background:rgba(255,255,255,.7);
        }
        > a {
            text-decoration: none;
            top: 20px;
            right:20px;
            .fa {
                margin-right: 3px;
            }
            &.addcommentaires{
                color:#000;
                background:rgba(255,255,255,.7);
            }
        }
    }

    .bx-pager{
        display: none;
    }
}

@media #{$medium-and-down}{
    .accueil_diaporama_template_5 .slide{
        .center-img-slider{
            height:250px;
        }
        > div {
            width: calc(100% / 2 - 10px);
            margin-right: 10px;
            &:nth-child(2){
                margin-right:0;
            }
            &:nth-child(3){
                margin-right:10px;
            }
            &:nth-child(n+3){
                display: none;
            }
        }
    }
    .accueil_diaporama_template_5 .bx-viewport .slide{
        > div:nth-child(n+3) {
            display: inline-block;
        }
    }
}

@media #{$small-and-down}{
    .accueil_diaporama_template_5 .slide{
        .center-img-slider{
            height:200px;
        }
        > div {
            width: 100%;
            margin-right: 0;
            &:nth-child(1){
                margin-right:0;
            }
            &:nth-child(2){
                margin-right:0;
            }
            &:nth-child(n+2){
                display: none;
            }
        }
    }
    .accueil_diaporama_template_5 .bx-viewport .slide .slide-text {
        opacity: 1;
    }
    .accueil_diaporama_template_5 .bx-viewport .slide > div:nth-child(n+2) {
        display: inline-block;
    }
}